import { BaseService, BaseServiceOptions } from './BaseService';
import { mapApplicationForm } from './mappers/mapApplicationForm';
import { transformApplicationAgreements } from './transformers/transformApplicationAgreements';
import { transformApplicationForm } from './transformers/transformApplicationForm';
import { transformBankCard } from './transformers/transformBankCard';
import { transformLoan } from './transformers/transformLoan';
import { transformLoanApplication } from './transformers/transformLoanApplication';
import { transformOption } from './transformers/transformOption';
import { transformPaymentData } from './transformers/transformPaymentData';
import { transformPaymentMethod } from './transformers/transformPaymentMethod';
import { transformUserOption } from './transformers/transformUserOption';
import { Application } from './types/Application';
import { ApplicationAgreement } from './types/ApplicationAgreement';
import { ApplicationAgreementType } from './types/ApplicationAgreementType';
import { ApplicationFormType } from './types/ApplicationFormType';
import { ApplicationFormTypes } from './types/ApplicationFormTypes';
import { AttachmentType } from './types/AttachmentType';
import { AuthTokens } from './types/AuthTokens';
import { BankCard } from './types/BankCard';
import { BindCardMethod } from './types/BindCardMethod';
import { Document } from './types/Document';
import { Loan } from './types/Loan';
import { LoanApplication } from './types/LoanApplication';
import { Option } from './types/Option';
import { PaymentAction } from './types/PaymentAction';
import { PaymentData } from './types/PaymentData';
import { PaymentMethod } from './types/PaymentMethod';
import { PaymentMethodType } from './types/PaymentMethodType';
import { PaymentProvider } from './types/PaymentProvider';
import { ServiceType } from './types/ServiceType';
import { UserOption } from './types/UserOption';
import { UserProfile } from './types/UserProfile';
import { UserState } from './types/UserState';
import { UTMParams } from './types/UTMParams';

/**
 * Параметры инициализации сервиса.
 */
export type RpcServiceOptions = Omit<
  BaseServiceOptions,
  'refreshTokenMethod'
> & {
  /**
   * Отпечаток браузера клиента.
   */
  fingerprint?: string;

  /**
   * Смещение во времени в миллисекундах, которое отнимается от времени
   * истечения токена сессии для выполнения операции его обновления.
   * @default 60000
   */
  sessionRenewThreshold?: number;
};

/**
 * Сервис, который предоставляет методы для взаимодействия с RPC API Apollo.
 * @module RpcService
 */
export class RpcService extends BaseService {
  /**
   * Приводит входные параметры сервиса RPC к параметрам базового сервиса.
   * @param options Параметры сервиса RPC.
   */
  private static deriveOptions(options: RpcServiceOptions): BaseServiceOptions {
    const {
      fingerprint,
      sessionRenewThreshold = 60000,
      ...restOptions
    } = options;
    const headers = new Headers(options.headers);

    if (undefined !== fingerprint) {
      headers.set('X-DEVICE-ID', fingerprint);
    }

    const baseOptions: BaseServiceOptions = {
      ...restOptions,
      headers,
      refreshTokenMethod: {
        method: 'auth.refreshToken',
        dataMapper: (refreshToken) => ({ refresh_token: refreshToken }),
        resultMapper: (response) => ({
          accessToken: response.access_token,
          refreshToken: response.refresh_token,
        }),
        getExpiration: (jwtPublic: any) => {
          if (
            !jwtPublic ||
            !('exp' in jwtPublic) ||
            'number' !== typeof jwtPublic.exp
          ) {
            return undefined;
          }

          // Сессия обновляется за указанное в опции sessionRenewThreshold
          // время перед истечением.
          const expiration =
            Number(jwtPublic.exp) * 1000 - sessionRenewThreshold;

          return new Date(expiration);
        },
      },
    };

    return baseOptions;
  }

  /**
   * Создаёт экземпляр сервиса взаимодействия с API.
   * @param apiRoot URL конечной точки.
   * @param options Параметры сервиса RPC.
   * @param session Токены аутентификации и обновления сессии.
   */
  constructor(
    apiRoot: string,
    options: RpcServiceOptions = {},
    session?: AuthTokens,
  ) {
    const baseOptions = RpcService.deriveOptions(options);

    super(apiRoot, baseOptions, session);
  }

  /**
   * Задаёт опции сервиса RPC.
   * @param options Опции сервиса RPC.
   */
  public setOptions(options: RpcServiceOptions = {}) {
    const baseOptions = RpcService.deriveOptions(options);

    super.setOptions(baseOptions);
  }

  /**
   * Получает состояние клиента на сервере.
   * @param phoneNumber Номер телефона.
   * @throws RpcErrorCode.InvalidParams
   */
  public async getUserState(phoneNumber: string): Promise<UserState> {
    const response = await this.request('auth.getUserState', {
      phone_number: phoneNumber,
    });

    const { state } = response;

    return state as UserState;
  }

  /**
   * Регистрирует пользователя в системе.
   * @param phoneNumber Номер телефона.
   * @throws RpcErrorCode.InvalidParams
   * @throws RpcErrorCode.CredentialsNotFound
   * @throws RpcErrorCode.UnableToCreateUser
   * @throws RpcErrorCode.FingerprintNotFound
   * @throws RpcErrorCode.UnableToCreateToken
   */
  public async registerUserByPhone(phoneNumber: string): Promise<AuthTokens> {
    const response = await this.request('auth.registerUserByPhone', {
      phone_number: phoneNumber,
    });

    const { access_token: accessToken, refresh_token: refreshToken } = response;

    return {
      accessToken,
      refreshToken,
    };
  }

  /**
   * Производит аутентификацию, используя номер телефона и ПИН-код.
   * @param phoneNumber Номер телефона.
   * @param pin ПИН-код.
   * @throws RpcErrorCode.InvalidParams
   * @throws RpcErrorCode.CredentialsNotFound
   * @throws RpcErrorCode.CredentialsInvalid
   * @throws RpcErrorCode.FingerprintNotFound
   * @throws RpcErrorCode.UnableToCreateToken
   * @throws RpcErrorCode.ClientNotFoundByPhone
   */
  public async login(phoneNumber: string, pin: string): Promise<AuthTokens> {
    const { access_token: accessToken, refresh_token: refreshToken } =
      await this.request('v2.auth.login', {
        phone_number: phoneNumber,
        password: pin,
      });

    return {
      accessToken,
      refreshToken,
    };
  }

  /**
   * Запрашивает отправку одноразового кода подтверждения для смены или создания
   * ПИН-кода.
   * @param phoneNumber Номер телефона.
   * @throws RpcErrorCode.InvalidParams
   * @throws RpcErrorCode.ClientNotFoundByPhone
   * @throws RpcErrorCode.SmsLimitExceeded
   * @throws RpcErrorCode.SmsNotSent
   */
  public async sendConfirmationCode(phoneNumber: string) {
    await this.request('auth.sendConfirmationCode', {
      phone_number: phoneNumber,
    });
  }

  /**
   * Производит аутентификацию, используя номер телефона и сохраняет
   * предоставленный ПИН-код используя одноразовый код подтверждения.
   * @param phoneNumber Номер телефона.
   * @param pin Значение ПИН-кода.
   * @param code Одноразовый код подтверждения.
   * @throws RpcErrorCode.InvalidParams
   * @throws RpcErrorCode.CredentialsNotFound
   * @throws RpcErrorCode.CredentialsInvalid
   * @throws RpcErrorCode.ClientNotFoundByPhone
   * @throws RpcErrorCode.FingerprintNotFound
   * @throws RpcErrorCode.SetPasswordAttemptsExceeded
   * @throws RpcErrorCode.UnableToCreateToken
   * @throws RpcErrorCode.InvalidSetPasswordConfirmationCode
   */
  public async setUserPassword(
    phoneNumber: string,
    pin: string,
    code: string,
  ): Promise<AuthTokens> {
    const { access_token: accessToken, refresh_token: refreshToken } =
      await this.request('auth.setUserPassword', {
        phone_number: phoneNumber,
        password: pin,
        confirmation_code: code,
      });

    return {
      accessToken,
      refreshToken,
    };
  }

  /**
   * Запрашивает отправку одноразового кода подтверждения для произведения
   * процедуры оплаты без аутентификации.
   * @param phoneNumber Номер телефона.
   * @throws RpcErrorCode.InvalidParams
   * @throws RpcErrorCode.SmsNotSent
   * @throws RpcErrorCode.ClientNotFoundByCode
   */
  public async loginPaymentSendConfirmationCode(
    phoneNumber: string,
  ): Promise<void> {
    await this.request('auth.login.payment.sendConfirmationCode', {
      phone_number: phoneNumber,
    });
  }

  /**
   * Подтверждает операцию оплаты без аутентификации при помощи одноразового
   * кода подтверждения.
   * @param phoneNumber Номер телефона.
   * @param code Одноразовый код подтверждения.
   * @throws RpcErrorCode.InvalidParams
   * @throws RpcErrorCode.InvalidConfirmationCode
   */
  public async loginPaymentByPhone(
    phoneNumber: string,
    code: string,
  ): Promise<PaymentData> {
    const result = await this.request('auth.login.payment.confirm', {
      phone_number: phoneNumber,
      confirmation_code: code,
    });

    return transformPaymentData(result);
  }

  /**
   * Создаёт операцию оплаты без аутентификации с использованием номера
   * договора/займа и даты рождения.
   * @param loanNumber Номер займа/договора.
   * @param birthday Дата рождения в формате dd.mm.yyyy.
   * @throws RpcErrorCode.InvalidParams
   * @throws RpcErrorCode.LoanNotFound
   */
  public async loginPaymentByLoan(
    loanNumber: string,
    birthday: string,
  ): Promise<PaymentData> {
    const result = await this.request('auth.login.payment.createToken', {
      loan_number: loanNumber,
      birthday,
    });

    return transformPaymentData(result);
  }

  /**
   * Создаёт или обновляет форму заявки.
   * @param formType Тип заявки.
   * @param form Данные формы.
   * @throws RpcErrorCode.InvalidParams
   * @throws RpcErrorCode.ApplicationFormAlreadySigned
   * @throws RpcErrorCode.LoanApplicationStatusInvalid
   */
  public async applicationFormCreateOrUpdate<
    TFormType extends keyof ApplicationFormTypes,
  >(
    formType: TFormType,
    form: ApplicationFormTypes[TFormType],
  ): Promise<ApplicationFormTypes[TFormType]> {
    const result = await this.request(
      'applicationForm.createOrUpdate',
      mapApplicationForm(formType, form),
    );

    return transformApplicationForm(formType, result);
  }

  /**
   * Запрашивает отправку одноразового кода подтверждения для произведения
   * процедуры подписи анкеты.
   * @param formType Тип заявки.
   * @throws RpcErrorCode.InvalidParams
   * @throws RpcErrorCode.ApplicationFormAlreadySigned
   * @throws RpcErrorCode.ApplicationSmsLimitExceeded
   * @throws RpcErrorCode.LoanApplicationStatusInvalid
   * @throws RpcErrorCode.ApplicationNotExists
   */
  public async applicationFormSendConfirmationCode(
    formType: ApplicationFormType,
  ): Promise<void> {
    await this.request('applicationForm.sendConfirmationCode', {
      application_type: formType,
    });
  }

  /**
   * Запрашивает список соглашений для указанного типа заявки.
   * @param formType Тип заявки.
   * @throws RpcErrorCode.InvalidParams
   * @throws RpcErrorCode.LoanApplicationStatusInvalid
   * @throws RpcErrorCode.ApplicationNotExists
   */
  public async applicationFormGetAgreements(
    formType: ApplicationFormType,
  ): Promise<ApplicationAgreement[]> {
    const result = await this.request('applicationForm.getAgreements', {
      // BUG: Если указать LoanApplicationForm, PHP не возвращает НИЧЕГО.
      application_type:
        formType === ApplicationFormType.LoanApplicationForm
          ? undefined
          : formType,
    });

    return transformApplicationAgreements(result);
  }

  /**
   * Возвращает активную анкету для последней активной заявки.
   * @param formType Тип заявки.
   * @throws RpcErrorCode.InvalidParams
   * @throws RpcErrorCode.LoanApplicationStatusInvalid
   * @throws RpcErrorCode.ApplicationNotExists
   */
  public async applicationFormGetLast<
    TFormType extends keyof ApplicationFormTypes,
  >(formType: TFormType): Promise<Application<TFormType>> {
    const result = await this.request('v2.applicationForm.getLast', {
      application_type: formType,
    });

    return {
      id: result.id,
      status: result.status,
      fields: transformApplicationForm(formType, result.fields),
    };
  }

  /**
   * Осуществляет заключение соглашений используя одноразовый код подтверждения.
   * @param formType Тип заявки.
   * @param code Одноразовый код подтверждения.
   * @param agreements Соглашения клиента.
   * @throws RpcErrorCode.InvalidParams
   * @throws RpcErrorCode.ApplicationFormAlreadySigned
   * @throws RpcErrorCode.ApplicationNotExists
   * @throws RpcErrorCode.ApplicationFormSignLimitExceeded
   * @throws RpcErrorCode.ApplicationFormConfirmationInvalid
   * @throws RpcErrorCode.LoanApplicationStatusInvalid
   */
  public async applicationFormSign<
    TFormType extends keyof ApplicationFormTypes,
  >(
    formType: TFormType,
    code: string,
    agreements: {
      [K in ApplicationAgreementType]?: boolean;
    },
  ): Promise<void> {
    await this.request('applicationForm.sign', {
      application_type: formType,
      confirmation_code: code,
      agreements,
    });
  }

  /**
   * Получает данные привязанных банковских карт.
   */
  public async paymentGetCards(): Promise<BankCard[]> {
    const result: any[] = await this.request('payment.getCards');

    return result.map(transformBankCard);
  }

  /**
   * Запрашивает выдачу денег на указанную карту.
   * @throws RpcErrorCode.ClientNotIdentified
   * @throws RpcErrorCode.LoanIsNotSigned
   * @throws RpcErrorCode.TryAnotherCard
   * @throws RpcErrorCode.PayoutCardNotAllowed
   */
  public async paymentPayout(cardID: number, utm?: UTMParams): Promise<void> {
    await this.request('payment.payout', {
      card_id: cardID,
      utm,
    });
  }

  /**
   * Запрашивает информацию о способе привязке банковской карты.
   */
  public async paymentBindCard(): Promise<BindCardMethod> {
    const result = await this.request('payment.bindCard');

    return {
      type: result.type,
      meta: {
        url: result.meta.url,
      },
    };
  }

  /**
   * Запрашивает информацию о способе произведения оплаты.
   * @param paymentProvider Платёжный шлюз.
   * @param paymentMethod Используемый метод оплаты.
   * @param serviceType Тип услуги для оплаты.
   * @param amount Сумма.
   * @param utm UTM-маркеры перенаправления.
   */
  public async paymentGetInfo(
    paymentProvider: PaymentProvider,
    paymentMethod: PaymentMethodType,
    serviceType: ServiceType,
    amount: number,
    utm?: UTMParams,
  ): Promise<PaymentAction> {
    const result = await this.request('v2.payment.getInfo', {
      amount,
      payment_method_alias: paymentMethod,
      payment_provider_alias: paymentProvider,
      service_type: serviceType,
      utm,
    });

    return {
      url: result.url,
      type: result.type_action,
    };
  }

  /**
   * Запрашивает информацию о способе произведения оплаты без аутентификации.
   * @param paymentProvider Платёжный шлюз.
   * @param paymentMethod Используемый метод оплаты.
   * @param amount Сумма.
   * @param token Токен сессии.
   * @param utm UTM-маркеры перенаправления.
   */
  public async paymentGetInfoByToken(
    paymentProvider: PaymentProvider,
    paymentMethod: PaymentMethodType,
    amount: number,
    token: string,
    utm?: UTMParams,
  ): Promise<PaymentAction> {
    const result = await this.request('payment.getInfoByToken', {
      amount,
      payment_method_alias: paymentMethod,
      payment_provider_alias: paymentProvider,
      token,
      utm,
    });

    return {
      url: result.url,
      type: result.type_action,
    };
  }

  /**
   * Запрашивает информацию о возможных способах произведения оплаты.
   * @param serviceType Тип услуги для оплаты.
   * @param amount Сумма.
   */
  public async paymentGetMethods(
    serviceType: ServiceType,
    amount: number,
  ): Promise<PaymentMethod[]> {
    const result = await this.request('payment.getPaymentMethods', {
      amount,
      service_type: serviceType,
    });

    return (result as any[]).map(transformPaymentMethod);
  }

  /**
   * Запрашивает информацию о возможных способах произведения оплаты.
   * @param serviceType Тип услуги для оплаты.
   * @param amount Сумма.
   * @param token Токен сессии.
   */
  public async paymentGetMethodsByToken(
    serviceType: ServiceType,
    amount: number,
    token: string,
  ): Promise<PaymentMethod[]> {
    const result = await this.request('payment.getPaymentMethodsByToken', {
      amount,
      service_type: serviceType,
      token,
    });

    return (result as any[]).map(transformPaymentMethod);
  }

  /**
   * Загружает или обновляет файл-вложение пользователя.
   * @param type Тип вложения.
   * @param base64Blob Данные файла.
   * @returns Ссылка на загруженное вложение.
   */
  public async clientUploadAttachment(
    type: AttachmentType,
    base64Blob: string,
  ): Promise<string> {
    const result = await this.request('client.uploadAttachment', {
      type,
      file: base64Blob,
    });

    return result.url;
  }

  /**
   * Получает ссылку на файл-вложение пользователя.
   * @param type Тип вложения.
   * @returns Ссылка на загруженное вложение.
   * @throws RpcErrorCode.AttachmentNotFound
   */
  public async clientGetAttachment(type: AttachmentType): Promise<string> {
    const result = await this.request('client.getAttachment', {
      type,
    });

    return result.url;
  }

  /**
   * Получает профиль клиента.
   */
  public async clientGetProfile(): Promise<UserProfile> {
    const result = await this.request('client.getProfile');

    return {
      firstName: result.name ?? undefined,
      lastName: result.last_name ?? undefined,
      patronymicName: result.patronymic ?? undefined,
      email: result.email ?? undefined,
      isVerified: result.is_verified,
      verifiedStatus: result.verified_status,
      phoneNumber: result.phone,
      type: result.type,
    };
  }

  /**
   * Получает доступные опции для клиента.
   */
  public async clientGetOptionsAvailable(): Promise<UserOption<any>[]> {
    const result = await this.request('client.options.getAvailable');

    return (result as any[]).map(transformUserOption);
  }

  /**
   * Получает данные активной заявки на заём.
   */
  public async loanApplicationGetActive(): Promise<LoanApplication> {
    const result = await this.request('loanApplication.getActive');

    return transformLoanApplication(result);
  }

  /**
   * Обновляет или создаёт данные заявки на заём.
   * @param period Срок займа.
   * @param amount Сумма займа.
   * @param utm UTM-маркеры перенаправления.
   */
  public async loanApplicationCreateOrUpdate(
    period: number,
    amount: number,
    utm?: UTMParams,
  ): Promise<LoanApplication> {
    const result = await this.request('loanApplication.createOrUpdate', {
      period,
      amount,
      utm,
    });

    return transformLoanApplication(result);
  }

  /**
   * Получает данные об активном займе.
   */
  public async loanGetActive(): Promise<Loan | undefined> {
    const result = await this.request('loan.getActive');

    return result ? transformLoan(result) : undefined;
  }

  /**
   * Получает данные документов, подписание которых необходимо для заключения
   * договора на заём.
   */
  public async loanGetSignDocuments(): Promise<Document[]> {
    const result = await this.request('loan.getSignDocuments');

    return result;
  }

  /**
   * Выбирает резервный продукт займа.
   */
  public async loanChooseReserveProduct(): Promise<void> {
    await this.request('loan.chooseReserveProduct');
  }

  /**
   * Выбирает оригинальный продукт займа.
   */
  public async loanChooseOriginalProduct(): Promise<void> {
    await this.request('loan.chooseOriginalProduct');
  }

  /**
   * Отправляет одноразовый код подтверждения для подписи договора.
   * @throws RpcErrorCode.LoanSmsLimitExceeded
   */
  public async loanSendSignCode(): Promise<void> {
    await this.request('loan.sendSignCode');
  }

  /**
   * Подписывает договор используя одноразовый код подтверждения.
   * @param code Код подтверждения.
   * @param utm UTM-маркеры перенаправления.
   * @throws RpcErrorCode.LoanSignInvalidCode
   * @throws RpcErrorCode.LoanSignLimitExceeded
   */
  public async loanConfirmSignCode(
    code: string,
    utm?: UTMParams,
  ): Promise<void> {
    await this.request('loan.confirmSignCode', {
      sign_code: code,
      utm,
    });
  }

  /**
   * Получает список доступных опций займа.
   */
  public async loanOptionGetAvailable(): Promise<Option<any>[]> {
    const result = await this.request('loan.option.getAvailable');

    return (result as any[]).map(transformOption);
  }

  /**
   * Выбирает опцию займа, получая список документов для подписания.
   * @param optionID Уникальный идентификатор опции.
   * @throws RpcErrorCode.LoanOptionNotExists
   * @throws RpcErrorCode.LoanOptionNotAvailable
   * @throws RpcErrorCode.LoanNotFound
   */
  public async loanOptionChoose(optionID: number): Promise<Document[]> {
    const result = await this.request('loan.option.choose', {
      option_id: String(optionID),
    });

    return (result.documents as any[]).map((document) => ({
      type: document.type,
      url: document.url,
      name: document.name,
    }));
  }

  /**
   * Вызывает отправку кода подтверждения на подписание договора опции займа.
   * @param optionID Уникальный идентификатор опции.
   * @throws RpcErrorCode.LoanNotFound
   * @throws RpcErrorCode.LoanOptionNotExists
   * @throws RpcErrorCode.LoanOptionNotAvailable
   * @throws RpcErrorCode.LoanOptionSmsLimitExceeded
   * @throws RpcErrorCode.LoanOptionSignLimitExceeded
   */
  public async loanOptionSendConfirmationCode(optionID: number): Promise<void> {
    await this.request('loan.option.sendConfirmationCode', {
      option_id: String(optionID),
    });
  }

  /**
   * Выбирает опцию займа, получая список документов для подписания.
   * @param optionID Уникальный идентификатор опции.
   * @param code Одноразовый код подтверждения.
   * @param utm UTM-маркеры перенаправления.
   * @throws RpcErrorCode.LoanNotFound
   * @throws RpcErrorCode.LoanOptionNotExists
   * @throws RpcErrorCode.LoanOptionNotAvailable
   * @throws RpcErrorCode.LoanOptionSignLimitExceeded
   * @throws RpcErrorCode.LoanOptionInvalidConfirmationCode
   */
  public async loanOptionConfirmSignCode(
    optionID: number,
    code: string,
    utm?: UTMParams,
  ): Promise<void> {
    await this.request('loan.option.confirmSignCode', {
      option_id: String(optionID),
      sign_code: code,
      utm,
    });
  }
}
