import { LazyStore, reactive } from '@devim-front/store';
import { observable, action } from 'mobx';

import { Page, Store as RoutingStore } from 'modules/common/routing';
import { ApplicationService as ApplicationMetricService } from 'modules/common/metrics';
import { Store as ApplicationStore } from 'modules/application/core';
import { applyFetchable } from 'modules/common/stores';
import { CompatService } from 'services/CompatService';
import { LimitExceededError, InvalidCodeError } from 'modules/confirmation';
import { ApplicationFormType } from 'services/RpcService/types/ApplicationFormType';
import { ApplicationAgreementType } from 'services/RpcService/types/ApplicationAgreementType';
import { ApplicationAgreement } from 'services/RpcService/types/ApplicationAgreement';
import { RpcError } from 'services/RpcService/errors/RpcError';
import { RpcErrorCode } from 'services/RpcService/types/RpcErrorCode';

/**
 * Хранилище соглашений и подписи.
 */
@reactive
export class Store extends applyFetchable(LazyStore) {
  /**
   * Ключ хранения данных соглашений в localStorage.
   */
  private AGREEMENTS_KEY = 'applicationAgreementsValues';

  /**
   * Экземпляр сервиса RPC API.
   */
  private get rpc() {
    return CompatService.get(this).rpcService;
  }

  /**
   * Список соглашений, которые нужно принять при подписании заявки.
   */
  @observable
  public model?: ApplicationAgreement[] = undefined;

  /**
   * Выбранные пользователем соглашения.
   */
  public values?: Record<ApplicationAgreementType, boolean> = undefined;

  /**
   * Ссылка на хранилище заявки.
   */
  private get application() {
    return ApplicationStore.get(this);
  }

  /**
   * Хранилище состояния роутера.
   */
  private get routing() {
    return RoutingStore.get(this);
  }

  /**
   * Экземпляр сервиса метрики.
   */
  private get applicationMetric() {
    return ApplicationMetricService.get(this);
  }

  /**
   * Задаёт новое значение свойству `agreementsValues`.
   * @param values Новое значение.
   */
  private setAgreementsValues = (
    values?: Record<ApplicationAgreementType, boolean>,
  ) => {
    this.values = values;

    if (values != null) {
      const value = JSON.stringify(values);
      window.localStorage.setItem(this.AGREEMENTS_KEY, value);
      return;
    }

    window.localStorage.removeItem(this.AGREEMENTS_KEY);
  };

  /**
   * Присваивает новое значение свойству `model`.
   * @param agreements Список соглашений или `undefined`.
   */
  @action
  private setModel = (agreements?: ApplicationAgreement[]) => {
    this.model = agreements;
  };

  /**
   * @inheritdoc
   */
  public async fetch() {
    const agreements = await this.rpc.applicationFormGetAgreements(
      ApplicationFormType.LoanApplicationForm,
    );

    const savedAgreements = window.localStorage.getItem(this.AGREEMENTS_KEY);
    const values = savedAgreements ? JSON.parse(savedAgreements) : undefined;

    return () => {
      this.setModel(agreements);
      this.setAgreementsValues(values);
    };
  }

  /**
   * Запрашивает у сервера под подтверждения заявки через СМС.
   * @param values Коллекция соглашений, которую выбрал
   * пользователь.
   */
  public requestCode = async (
    values: Record<ApplicationAgreementType, boolean>,
  ) => {
    this.setAgreementsValues(values);

    try {
      await this.rpc.applicationFormSendConfirmationCode(
        ApplicationFormType.LoanApplicationForm,
      );
    } catch (error) {
      if (RpcError.isType(error, RpcErrorCode.ApplicationSmsLimitExceeded)) {
        throw new LimitExceededError();
      }

      throw error;
    }
  };

  /**
   * Подписывает заявку с помощью кода, полученного в СМС.
   * @param code Код подтверждения из СМС.
   */
  public confirmCode = async (code: string) => {
    if (this.values == null) {
      throw new Error(`this.values is undefined`);
    }

    try {
      await this.rpc.applicationFormSign(
        ApplicationFormType.LoanApplicationForm,
        code,
        this.values,
      );
    } catch (error) {
      if (
        RpcError.isType(error, RpcErrorCode.ApplicationFormSignLimitExceeded)
      ) {
        throw new LimitExceededError();
      }

      if (
        RpcError.isType(error, RpcErrorCode.ApplicationFormConfirmationInvalid)
      ) {
        throw new InvalidCodeError();
      }

      throw error;
    }

    this.applicationMetric.signed();

    this.setAgreementsValues(undefined);

    await this.application.renew();
    await this.routing.push(Page.ACCOUNT);
  };

  /**
   * @inheritdoc
   */
  public clear() {
    this.model = undefined;
    this.values = undefined;
  }
}
