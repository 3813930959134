/**
 * Типы соглашений при оформлении заявки.
 */
export enum ApplicationAgreementType {
  /**
   * Аналог цифровой подписи.
   */
  SignatureAnalogue = 'analog_of_the_autographic_signature_agreement',

  /**
   * Правила предоставления юридической услуги.
   */
  LegalSupportRules = 'option_legal_support_rules',

  /**
   * Условия предоставления услуг для первичных клиентов.
   */
  ServiceFirst180 = 'service_first180',

  /**
   * Условия предоставления услуг с округлением для первичных клиентов.
   */
  ServiceFirst180Round = 'service_first180_round',

  /**
   * Условия предоставления услуг для повторных клиентов.
   */
  ServiceRepeat = 'service_repeat180',

  /**
   * Согласие на обработку персональных данных.
   */
  PersonalDataProcessing = 'personal_info',

  /**
   * Согласие на передачу данных третьим лицам.
   */
  CessionOfClaimRights = 'give_up_claim_rights',
}
