import { ComponentPropsWithoutRef, FC } from 'react';

import { Container } from 'modules/common/ui';

import { Root } from './List.Root';
import { Content } from './List.Content';
import { Info } from './List.Info';
import { Title } from './List.Title';
import { Description } from './List.Description';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Root>, 'layout'>;

/**
 * Отображает список подвала страницы.
 */
export const List: FC<Props> = ({ ...props }) => (
  <Root {...props}>
    <Container size="large" offset="small">
      <Content>
        <Info>
          <Title>Сумма займа</Title>
          <Description>от 1 000 до 30 000 рублей</Description>
        </Info>
        <Info>
          <Title>Срок займа</Title>
          <Description>от 15 до 180 календарных дней</Description>
        </Info>
        <Info>
          <Title>Процентная ставка</Title>
          <Description>от 0% до 1% в день</Description>
        </Info>
        <Info>
          <Title>ПСК (полная стоимость кредита)</Title>
          <Description>от 270,026% до 365,00% годовых</Description>
        </Info>
        <Info>
          <Title>ИНН</Title>
          <Description>7838031546</Description>
        </Info>
        <Info>
          <Title>ОГРН</Title>
          <Description>1157847007702</Description>
        </Info>
        <Info>
          <Title>Свидетельство о внесении в государственный реестр МФО</Title>
          <Description>№ 001503140006969 от 12.10.2015</Description>
        </Info>
      </Content>
    </Container>
  </Root>
);
