import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Компонент для отображение всех иконок в части подвала страницы.
 */
export const All = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem;

  max-width: 23.125rem;

  @media ${lessThanContainer('small')} {
    justify-content: center;
  }
`;
