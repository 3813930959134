import { LinkButton } from 'modules/common/ui';
import { Package } from 'modules/loan';
import { ApplicationAgreementType } from 'services/RpcService/types/ApplicationAgreementType';

/**
 * Генерирует текст внутри чекбокса.
 * @param type Тип соглашения.
 * @param href Ссылка на документ соглашения.
 */
export const renderText = (type: ApplicationAgreementType, href: string) => {
  switch (type) {
    case ApplicationAgreementType.SignatureAnalogue:
      return (
        <>
          Настоящим выражаю свое согласие на принятие условий{' '}
          <LinkButton
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            variant="plain"
            color="info"
          >
            Соглашения об использовании АСП.
          </LinkButton>
        </>
      );

    case ApplicationAgreementType.CessionOfClaimRights:
      return (
        <>
          Настоящим соглашаюсь на уступку прав (требований) по договору третьим
          лицам при условии соблюдения Обществом требований действующего
          законодательства.
        </>
      );

    case ApplicationAgreementType.PersonalDataProcessing:
      return (
        <>
          Настоящим выражаю своё согласие на обработку{' '}
          <LinkButton
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            variant="plain"
            color="info"
          >
            персональных данных и на запрос кредитного отчета в бюро кредитных
            историй.
          </LinkButton>
        </>
      );

    case ApplicationAgreementType.ServiceRepeat:
      return (
        <>
          Настоящим подтверждаю, что полностью ознакомлен с{' '}
          <LinkButton
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            variant="plain"
            color="info"
          >
            условиями предоставления услуг в рамках пакета “{Package.REPEAT}”
          </LinkButton>{' '}
          и согласен на приобретение данного пакета.
        </>
      );

    case ApplicationAgreementType.ServiceFirst180:
    case ApplicationAgreementType.ServiceFirst180Round:
      return (
        <>
          Настоящим подтверждаю, что полностью ознакомлен с{' '}
          <LinkButton
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            variant="plain"
            color="info"
          >
            условиями предоставления услуг в рамках пакета “{Package.FIRST}”
          </LinkButton>{' '}
          и согласен на приобретение данного пакета.
        </>
      );

    default: {
      return null;
    }
  }
};
