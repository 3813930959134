import { ComponentPropsWithoutRef, FC, useCallback } from 'react';

import { Page } from 'modules/common/routing';
import { Provider } from 'modules/refusalOfService/refusalAccess';

import { useMenu } from '../../MenuContext';

import { Root } from './SubMenu.Root';
import { Item } from './SubMenu.Item';
import { LinkButton } from './SubMenu.LinkButton';
import { Divider } from './SubMenu.Divider';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Вложенное меню шапки.
 */
export const SubMenu: FC<Props> = ({ ...props }) => {
  const { setOpen } = useMenu();

  const handleClick = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Root {...props}>
      <Item>
        <LinkButton href={Page.LEGAL_SUPPORT} onClick={handleClick}>
          Дополнительные услуги
        </LinkButton>
      </Item>
      <Provider>
        <Divider />
        <Item>
          <LinkButton href={Page.REFUSAL_OF_SERVICE} onClick={handleClick}>
            Заявление на отказ от услуги
          </LinkButton>
        </Item>
      </Provider>
    </Root>
  );
};
