import { Service } from './Service';

import { Event } from '../types/Event';

/**
 * Сервис содержит методы для отправки событий метрики калькулятора.
 */
export class CalculatorService extends Service {
  /**
   * Клиент нажал на кнопку калькулятора.
   */
  submitted() {
    this.send(Event.CALCULATOR_SUBMITTED);
  }
}
