import styled from '@emotion/styled';

import { Divider as Base } from '../Divider';

/**
 * Разделитель пунктов вложенного меню шапки.
 */
export const Divider = styled(Base)`
  display: block;
`;
