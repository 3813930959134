import { ComponentPropsWithoutRef, FC } from 'react';

import { Checkbox } from 'modules/common/ui';
import { ApplicationAgreement } from 'services/RpcService/types/ApplicationAgreement';

import { renderText } from './Item.renderText';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Checkbox>,
  'name' | 'valid' | 'children'
> & {
  /**
   * Модель соглашения, которому соответствует данный элемент.
   */
  model: ApplicationAgreement;
};

/**
 * Отображает отдельный чекбокс соглашения.
 */
export const Item: FC<Props> = ({ model, ...props }) => (
  <Checkbox {...props} name={model.type} id={model.type} valid={false}>
    {renderText(model.type, model.documentUrl)}
  </Checkbox>
);
