import * as yup from 'yup';

import { Field } from '../types';

/**
 * Схема формы информации о кредитных обязательствах.
 */
export const schema = yup.object().shape({
  /**
   * Кредитные обязательства.
   */
  [Field.CREDIT_OBLIGATIONS]: yup.string(),
});
