import { FC, ComponentPropsWithoutRef, useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';

import { Checkbox } from 'modules/common/ui';

import { Store } from '../../../stores';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Checkbox>, 'value'>;

/**
 * Отображает чекбокс "согласен со всеми условиями".
 */
const GroupCheckbox: FC<Props> = ({ onChange, ...props }) => {
  const { model = [] } = Store.use();
  const { values, setValues } = useFormikContext<any>();

  const names = useMemo(() => model.map((item) => item.type), [model]);

  const isChecked = useMemo(
    () => (names.length > 0 ? names.every((item) => values[item]) : false),
    [values, names],
  );

  const handleChange = useCallback(() => {
    const nextValue = !isChecked;

    const nextValues = names.reduce(
      (result: Record<string, any>, name: string) => ({
        ...result,
        [name]: nextValue,
      }),
      {},
    );

    setValues(nextValues, true);

    if (onChange) {
      onChange(nextValue);
    }
  }, [names, isChecked, onChange, setValues]);

  return <Checkbox {...props} onChange={handleChange} value={isChecked} />;
};

const component = observer(GroupCheckbox);
export { component as GroupCheckbox };
