import { FC, SVGProps } from 'react';

/**
 * Иконка "Добавить"
 */
export const Add: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3a.9.9 0 00-.9.9v7.2H3.9a.9.9 0 000 1.8h7.2v7.2a.9.9 0 101.8 0v-7.2h7.2a.9.9 0 000-1.8h-7.2V3.9A.9.9 0 0012 3z"
      fill="currentColor"
    />
  </svg>
);
