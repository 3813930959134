import * as yup from 'yup';

import { FileHelper } from 'modules/common/values';

import { Field } from '../types';
import { SchemaHelper } from '../helpers';

/**
 * Схема паспортных данных пользователя.
 */
export const schema = yup.object().shape({
  /**
   * Поле ввода серии и номера паспорта не может быть пустым.
   * Проверка на корректный формат.
   * Проверка реальный ли паспорт.
   */
  [Field.NUMBER]: yup
    .string()
    .required('Укажите серию и номер паспорта')
    .matches(/^\d{4}-\d{6}$/, 'Неверный формат серии и номера паспорта')
    .test(
      Field.NUMBER,
      'Указанный номер паспорта не существует',
      SchemaHelper.validatePassportNumber,
    ),

  /**
   * Поле ввода даты рождения не может быть пустым.
   * Проверка на корректный формат.
   */
  [Field.BIRTH_DATE]: yup
    .string()
    .required('Укажите дату вашего рождения')
    .test(Field.BIRTH_DATE, function validateBirthDate(value) {
      if (!value) {
        return true;
      }

      const { createError } = this;
      const message = SchemaHelper.validateBirthDate(value);

      if (message) {
        return createError({ message });
      }

      return true;
    }),

  /**
   * Поле ввода места рождения не может быть пустым.
   * Проверка на корректный формат.
   */
  [Field.BIRTH_PLACE]: yup
    .string()
    .required('Укажите место рождения как в паспорте')
    .matches(
      /^[а-яА-ЯёЁ.\s'`-]+$/,
      'Поле может содержать только русские буквы, точки, пробелы, дефисы и апострофы',
    ),

  /**
   * Поле кода подразделения не может быть пустым.
   */
  [Field.CODE]: yup
    .string()
    .nullable()
    .required('Укажите код подразделения')
    .matches(/^\d{3}-\d{3}$/, 'Неверный формат кода подразделения'),

  /**
   * Поле ввода даты выдачи паспорта не может быть пустым.
   * Проверка на корректный формат.
   */
  [Field.DATE]: yup
    .string()
    .required('Укажите дату выдачи паспорта')
    .test(Field.DATE, function validatePassportDate(value) {
      if (!value) {
        return true;
      }

      const { createError, parent } = this;
      const { birthDate } = parent;

      const message =
        SchemaHelper.validatePassportDate(value) ||
        SchemaHelper.validateBirthDate(birthDate);

      if (message) {
        return createError({ message });
      }

      const expirationMessage = SchemaHelper.validatePassportExpiration(
        value,
        birthDate,
      );
      return expirationMessage
        ? createError({ message: expirationMessage })
        : true;
    }),

  /**
   * Поле органа, выдавшего паспорт, не может быть пустым.
   */
  [Field.UNIT]: yup.string().nullable().required('Укажите, кем выдан паспорт'),

  /**
   * Поле СНИЛС должно быть валидно.
   */
  [Field.SNILS]: yup
    .string()
    .optional()
    .nullable()
    .test(Field.SNILS, 'Указан неверный номер СНИЛС', (value) => {
      if (value == null) {
        return true;
      }

      return value
        ? value === '___-___-___ __' || SchemaHelper.validateSnils(value)
        : false;
    }),
  /**
   *
   */
  [Field.INN]: yup
    .string()
    .optional()
    .nullable()
    .matches(/^\d{12}$/, 'Неверный формат ИНН')
    .test(Field.INN, 'Неверный формат ИНН', (value) => {
      if (value == null) {
        return true;
      }

      const newValue = value.split('').map((n) => Number(n));

      const ctrlTable1 = [7, 2, 4, 10, 3, 5, 9, 4, 6, 8];
      const ctrlTable2 = [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8];

      let ctrlNum1 =
        ctrlTable1.reduce((prev, current, index) => {
          return prev + current * newValue[index];
        }, 0) % 11;

      let ctrlNum2 =
        ctrlTable2.reduce((prev, current, index) => {
          return prev + current * newValue[index];
        }, 0) % 11;

      ctrlNum1 = ctrlNum1 === 10 ? 0 : ctrlNum1;
      ctrlNum2 = ctrlNum2 === 10 ? 0 : ctrlNum2;

      return newValue[10] === ctrlNum1 && newValue[11] === ctrlNum2;
    }),

  [Field.PHOTO]: yup
    .mixed()
    .optional()
    .test(
      `${Field.PHOTO}_size`,
      'Размер файла не должен превышать 10МБ',
      (value?: any) => {
        if (value == null) {
          return true;
        }

        return value.size < 10 * 1024 * 1024;
      },
    )
    .test(
      `${Field.PHOTO}_accept`,
      'Загружаемый файл может быть только изображением',
      (value?: any) => {
        if (value == null) {
          return true;
        }

        return FileHelper.isMimeType(value, 'image/*');
      },
    ),
});
