import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Элемент для компонентов информации.
 */
export const Content = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.light.separator};

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4.375rem;

  padding: 3.75rem 0;

  @media ${lessThanContainer('large')} {
    gap: 3.75rem 3.125rem;
  }

  @media ${lessThanContainer('medium')} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${lessThanContainer('small')} {
    gap: 1.875rem;
    grid-template-rows: repeat(3, auto);
  }
`;
