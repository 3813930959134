import { ApplicationFormLegalSupport } from '../types/ApplicationFormLegalSupport';
import { ApplicationFormLoan } from '../types/ApplicationFormLoan';
import { ApplicationFormServicePaymentRefund } from '../types/ApplicationFormServicePaymentRefund';
import { ApplicationFormType } from '../types/ApplicationFormType';
import { ApplicationFormTypes } from '../types/ApplicationFormTypes';
import { mapOmitNullish } from './mapOmitNullish';

/**
 * Приводит данные формы заявки к запросу, отправляемому на сервер.
 * @param formType Тип формы.
 * @param form Данные формы.
 */
export function mapApplicationForm<
  TFormType extends keyof ApplicationFormTypes,
>(formType: TFormType, form: ApplicationFormTypes[TFormType]) {
  let formData: Record<string, any>;

  switch (formType) {
    case ApplicationFormType.LoanApplicationForm:
      formData = mapApplicationFormLoan(form as ApplicationFormLoan);
      break;

    case ApplicationFormType.LegalSupport:
      formData = mapApplicationFormLegalSupport(
        form as ApplicationFormLegalSupport,
      );
      break;

    case ApplicationFormType.ServicePaymentRefund:
      formData = mapApplicationFormServicePaymentRefund(
        form as ApplicationFormServicePaymentRefund,
      );
      break;

    default:
      return undefined as never;
  }

  return {
    ...formData,
    application_type: formType,
  };
}

/**
 * Приводит данные формы заявки к запросу на заём.
 * @param form Данные формы.
 */
function mapApplicationFormLoan(form: ApplicationFormLoan) {
  return {
    client_email: form.email,
    client_inn: form.taxNumber,

    ...Array.from('123').reduce((acc, key, index) => {
      const contactPerson = form.contactPersons[index];

      acc[`contact_first_name${key}`] = contactPerson?.firstName ?? null;
      acc[`contact_last_name${key}`] = contactPerson?.lastName ?? null;
      acc[`contact_patronymic${key}`] = contactPerson?.patronymicName ?? null;
      acc[`contact_phone${key}`] = contactPerson?.phoneNumber ?? null;
      acc[`contact_relation${key}`] = contactPerson?.type ?? null;

      return acc;
    }, {} as Record<string, string | null>),

    address_city: form.registrationAddress.city,
    address_street: form.registrationAddress.street,
    address_house: form.registrationAddress.house,
    address_apartment: form.registrationAddress.apartment,
    address_country: form.registrationAddress.region,

    residence_address_city: form.residenceAddress.city,
    residence_address_street: form.residenceAddress.street,
    residence_address_house: form.residenceAddress.house,
    residence_address_apartment: form.residenceAddress.apartment,
    residence_address_country: form.residenceAddress.region,

    passport_last_name: form.lastName,
    passport_first_name: form.firstName,
    passport_patronymic: form.patronymicName,
    passport_date_of_birth: form.passportBirthDate,
    passport_series: form.passportSeries,
    passport_number: form.passportNumber,
    passport_department_code: form.passportDepartmentCode,
    passport_issue_date: form.passportIssueDate,
    passport_place_of_birth: form.passportBirthPlace,
    passport_issued_by: form.passportIssuer,
    passport_gender: form.gender,

    martial_status: form.martialStatus,
    martial_family_count: form.familyMembersCount,
    martial_children_count: form.childrenCount,
    martial_live_with_parents: form.isLivingWithParents,

    work_status: form.employment.status,
    work_employer: form.employment.organization,
    work_address: form.employment.address,
    work_phone: form.employment.phoneNumber,
    work_position: form.employment.position,
    work_salary: form.employment.monthlySalary,
    work_salary_day: form.employment.salaryDay,

    credit_obligations: form.creditObligations,
  };
}

/**
 * Приводит данные формы заявки к запросу на юридическую услугу.
 * @param form Данные формы.
 */
function mapApplicationFormLegalSupport(form: ApplicationFormLegalSupport) {
  return mapOmitNullish({
    client_email: form.email,
    passport_last_name: form.lastName,
    passport_first_name: form.firstName,
    passport_patronymic: form.patronymicName,
    passport_series: form.passportSeries,
    passport_number: form.passportNumber,
    passport_department_code: form.passportDepartmentCode,
    passport_issue_date: form.passportIssueDate,
    passport_place_of_birth: form.passportBirthPlace,
    passport_issued_by: form.passportIssuer,

    address_city: form.registrationAddress.city,
    address_street: form.registrationAddress.street,
    address_house: form.registrationAddress.house,
    address_apartment: form.registrationAddress.apartment,

    martial_live_with_parents: form.isLivingWithParents,
  });
}

/**
 * Приводит данные формы заявки к запросу на отказ от услуги.
 * @param form Данные формы.
 */
function mapApplicationFormServicePaymentRefund(
  form: ApplicationFormServicePaymentRefund,
) {
  return mapOmitNullish({
    email: form.email,

    loan_id: form.loanID,

    passport_last_name: form.lastName,
    passport_first_name: form.firstName,
    passport_patronymic: form.patronymicName,
    passport_series: form.passportSeries,
    passport_number: form.passportNumber,
    passport_department_code: form.passportDepartmentCode,
    passport_date_of_birth: form.passportBirthDate,
    passport_issue_date: form.passportIssueDate,
    passport_issued_by: form.passportIssuer,

    city: form.residenceAddress.city,
    street: form.residenceAddress.street,
    house: form.residenceAddress.house,
    apartment: form.residenceAddress.apartment,

    bank_name: form.bankName,
    bank_bik: form.bankIdentificationCode,
    bank_account_number: form.bankAccount,
    bank_client_full_name: form.bankRecipient,
  });
}
