import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Компонент заметки о СНИЛС.
 */
export const Note = styled.label`
  display: flex;
  align-items: center;

  height: 100%;

  font-size: 0.75rem;
  padding-top: 1.0625rem;

  @media ${lessThanContainer('small')} {
    margin-top: -0.625rem;
    padding-top: 0;
  }
`;
