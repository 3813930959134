import { FC, ComponentPropsWithoutRef, useCallback } from 'react';

import { Confirmation, Prevent } from 'modules/confirmation';
import { Store as ClientStore } from 'modules/client';
import { useFormGroup } from 'modules/common/ui';
import { Store as SigningStore } from 'modules/application/signing';

import { FormName } from '../../../types';

import { Root } from './ConfirmationForm.Root';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Confirmation>,
  'requestCode' | 'confirmCode' | 'phone'
>;

/**
 * Отображает форму подтверждения.
 */
export const ConfirmationForm: FC<Props> = ({ ...props }) => {
  const client = ClientStore.use();
  const { submit, pending } = useFormGroup();
  const signing = SigningStore.use();

  const requestCode = useCallback(async () => {
    if (pending) {
      return;
    }

    const [errors, values] = await submit();

    if (errors) {
      throw new Prevent('Форма заполнена неверно');
    }

    const agreements = (values as any)[FormName.AGREEMENTS];
    await signing.requestCode(agreements);
  }, [submit, pending, signing]);

  const confirmCode = useCallback(
    async (code: string) => {
      if (pending) {
        return;
      }

      const [errors] = await submit();

      if (errors) {
        throw new Prevent('Форма заполнена неверно');
      }

      await signing.confirmCode(code);
    },
    [submit, pending, signing],
  );

  return (
    <Root>
      <Confirmation
        {...props}
        requestCode={requestCode}
        confirmCode={confirmCode}
        phone={client.model.phoneNumber}
      />
    </Root>
  );
};
