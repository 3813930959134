import { Helper as Base } from '@devim-front/helper';

import { NumberHelper } from './NumberHelper';

/**
 * Содержит методы для работы с денежными суммами. Денежная сумма
 * представляется неотрицательным целым числом копеек.
 */
export class AmountHelper extends Base {
  /**
   * Преобразует дробное число вида "рубли.копейки" в корректную денежную сумму.
   * При этом, содержимое дробной части после второго знака не округляется, а
   * отбрасывается.
   * @param value Число.
   */
  public static create(value: number) {
    return Math.trunc(value * 100);
  }

  /**
   * Преобразует число с бэкенда в в число с плавающей точкой с точностью до сотых
   */
  public static parse(value: number) {
    return (value / 100).toFixed(2);
  }

  /**
   * Округляет денежную сумму до рублей по правилам математики и
   * преобразует её в строку в человекочитаемом формате "10 000".
   * @param amount Число.
   */
  public static formatDisplayInteger(amount: number) {
    const integer = Math.round(amount / 100);
    return NumberHelper.formatIntegerDisplay(integer);
  }

  /**
   * Переводит денежную сумму в рубли, округляя её по правилам математики.
   * @param amount Денежная сумма.
   */
  public static formatInteger(amount: number) {
    return Math.round(amount / 100);
  }

  /**
   * Преобразует указанную денежную сумму в строку в человекопонятном формате
   * "10 000,00".
   * @param amount Денежная сумма.
   */
  public static formatDisplayFloat(amount: number) {
    return NumberHelper.formatFloatDisplay(amount / 100);
  }

  /**
   * Возвращает строку, содержащую значок рубля в unicode.
   */
  public static getShortUnits() {
    return '₽';
  }

  /**
   * Возвращает строку, содержащую сумму и валюту для удобного чтения
   * "10 000 ₽"
   * @param amount Денежная сумма.
   */
  public static formatDisplayIntegerWithUnit(amount: number) {
    const text = AmountHelper.formatDisplayInteger(amount);
    const unit = AmountHelper.getShortUnits();
    return `${text} ${unit}`;
  }

  /**
   * Возвращает сумму займа, которая отображается, когда поле ввода не в
   * фокусе.
   * @param value Значение поля ввода.
   */
  public static inputFormat(value: number) {
    const text = NumberHelper.formatIntegerDisplay(value);
    const unit = AmountHelper.getShortUnits();
    return `${text} ${unit}`;
  }

  /**
   * Округляет указанную денежную сумму с заданной точностью. Округление
   * происходит по правилам математики.
   * @param amount Число.
   * @param precision Точность округления. Если точность округления
   * положительная, округляются копейки. Например, при точности 1 сумма 105
   * (1 рубль 5 копеек) будет округлена как 110 (1 рубль 10 копеек). Очевидно,
   * что при точности 2 и больше, сумма будет возвращена как есть. Если
   * точность отрицательная, то округляются рубли: например, при точности -2
   * сумма 15000 (150 рублей 0 копеек) будет округлена как 20000 (200 рублей).
   * При нулевой точности сумма будет округлена до рублей.
   */
  public static round(amount: number, precision: number = 0) {
    if (precision > 1) {
      return amount;
    }

    return NumberHelper.round(amount, precision - 2);
  }

  /**
   * Округляет указанную денежную сумму с заданной точностью. Округление
   * происходит к большей величине.
   * @param amount Число.
   * @param precision Точность округления. Если точность округления
   * положительная, округляются копейки. Например, при точности 1 сумма 105
   * (1 рубль 5 копеек) будет округлена как 110 (1 рубль 10 копеек). Очевидно,
   * что при точности 2 и больше, сумма будет возвращена как есть. Если
   * точность отрицательная, то округляются рубли: например, при точности -2
   * сумма 15000 (150 рублей 0 копеек) будет округлена как 20000 (200 рублей).
   * При нулевой точности сумма будет округлена до рублей.
   */
  public static ceil(amount: number, precision: number = 0) {
    if (precision > 1) {
      return amount;
    }

    return NumberHelper.ceil(amount, precision - 2);
  }

  /**
   * Округляет указанную денежную сумму с заданной точностью. Округление
   * происходит к меньшей величине.
   * @param amount Число.
   * @param precision Точность округления. Если точность округления
   * положительная, округляются копейки. Например, при точности 1 сумма 105
   * (1 рубль 5 копеек) будет округлена как 110 (1 рубль 10 копеек). Очевидно,
   * что при точности 2 и больше, сумма будет возвращена как есть. Если
   * точность отрицательная, то округляются рубли: например, при точности -2
   * сумма 15000 (150 рублей 0 копеек) будет округлена как 20000 (200 рублей).
   * При нулевой точности сумма будет округлена до рублей.
   */
  public static floor(amount: number, precision: number = 0) {
    if (precision > 1) {
      return amount;
    }

    return NumberHelper.floor(amount, precision - 2);
  }
}
