import { FC, ComponentPropsWithoutRef, useCallback } from 'react';
import { observer } from 'mobx-react';

import { FormGroup } from 'modules/common/ui';
import { Store as CoreStore, Status } from 'modules/application/core';

import { Store } from '../../stores';

import { Navigation } from './Navigation';
import { NavigationTop } from './NavigationTop';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof FormGroup> & {
  /**
   * Номер шага заявки.
   */
  step: number;
};

/**
 * Объединяет все вложенные формы в группу и добавляет под них кнопки
 * навигации по шагам заявки.
 */
const StepForms: FC<Props> = ({ onSubmit, children, step, ...props }) => {
  const store = Store.use();
  const applicationStore = CoreStore.use();

  const disabled = applicationStore.model?.status === Status.NONE;

  const handleSubmit = useCallback(
    async (forms: any) => {
      if (store.pending) {
        return;
      }

      await store.submitStep(step);

      if (onSubmit) {
        onSubmit(forms);
      }
    },
    [onSubmit, store, step],
  );

  return (
    <FormGroup {...props} onSubmit={handleSubmit}>
      <NavigationTop step={step} />
      {children}
      <Navigation step={step} disabled={disabled} />
    </FormGroup>
  );
};

const component = observer(StepForms);
export { component as StepForms };
