import styled from '@emotion/styled';

/**
 * Компонент для отображение описания.
 */
export const Description = styled.p`
  margin: 0.625rem 0 0;

  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
`;
