import { LazyStore, reactive } from '@devim-front/store';
import { action, computed, observable } from 'mobx';

import { Page, Store as RoutingStore } from 'modules/common/routing';
import { Service as PaymentService, PaymentType } from 'modules/repay';
import { applyFetchable } from 'modules/common/stores';
import { Type as DocumentTypeOld } from 'modules/docs';
import { CompatService } from 'services/CompatService';
import { LoanProduct } from 'services/RpcService/types/LoanProduct';
import { LoanStatus } from 'services/RpcService/types/LoanStatus';
import { PaymentProvider } from 'services/RpcService/types/PaymentProvider';
import { PaymentMethodType } from 'services/RpcService/types/PaymentMethodType';
import { ServiceType } from 'services/RpcService/types/ServiceType';
import { RpcError } from 'services/RpcService/errors/RpcError';
import { RpcErrorCode } from 'services/RpcService/types/RpcErrorCode';

import { Model } from './Model';
import { Source } from './Source';
import { Product } from './Product';
import { Status } from './Status';
import { Type } from './Type';

/**
 * Хранилище состояния займа пользователя.
 */
@reactive
export class Store extends applyFetchable(LazyStore) {
  /**
   * Модель займа. Если свойство равно `undefined`, то модель ещё не была
   * загружена с сервера.
   */
  @observable
  private unsafeModel?: Model = undefined;

  /**
   * Флаг, который указывает на доступность оплаты займа.
   */
  @observable
  public isActionBlocked = false;

  /**
   * Статус текущего займа пользователя. Если сведения о займе ещё не загружены
   * с сервера, свойство равно `undefined`.
   */
  @computed
  public get status() {
    return this.unsafeModel?.status;
  }

  /**
   * Является ли займ скорострелом.
   */
  @computed
  public get isAuto() {
    return this.unsafeModel?.source === Source.AUTO;
  }

  /**
   * Является ли сегодняшний день экватором для следующего регулярного платежа.
   */
  @computed
  public get isEquator() {
    const nextPaymentDate =
      this.model?.nextPayment?.dateAt?.getTime() ?? Date.now();

    return (
      Math.round((nextPaymentDate - Date.now()) / (1000 * 60 * 60 * 24)) < 15
    );
  }

  /**
   * Была ли примененна реструктуризация к займу
   */
  @computed
  public get isRestructured() {
    const option = this.model?.productOptions?.find(
      (item) => item.status === 'applied' && item.type === 'restructuring',
    );

    return Boolean(option);
  }

  /**
   * Ссылка на сервис RPC.
   */
  private get rpc() {
    return CompatService.get(this).rpcService;
  }

  /**
   * Ссылка на экземпляр сервиса маршрутизации.
   */
  private get routing() {
    return RoutingStore.get(this);
  }

  /**
   * Ссылка на экземпляр сервиса платежей.
   */
  public get payment() {
    return PaymentService.get(this);
  }

  /**
   * Модель займа. Если сведения о модели ещё не были загружены с сервера,
   * то обращение к свойству вызовет исключение.
   */
  @computed
  public get model() {
    if (this.unsafeModel == null) {
      throw new Error(
        `loan.Store is not ready. Please use touch() method before usage`,
      );
    }

    return this.unsafeModel;
  }

  /**
   * Задаёт новое значение свойству `model`.
   * @param model Новое значение.
   */
  @action
  private setModel = (model?: Model) => {
    this.unsafeModel = model;
  };

  /**
   * Функция, задающая параметр `isActionBlocked`
   * @param isActionBlocked
   * @private
   */
  @action
  private setIsActionBlocked(isActionBlocked: boolean) {
    this.isActionBlocked = isActionBlocked;
  }

  /**
   * Возвращает модель займа, заполненную значениями по умолчанию.
   */
  private getDefaultModel = () => {
    return {
      creationDate: new Date(),
      payoutDate: undefined,
      product: Product.MINI,
      productOptions: [],
      type: Type.PDL,
      interestRate: 0,
      freePeriod: 0,
      status: Status.NONE,
      number: '',
      period: 0,
      amount: 0,
      nextPayment: undefined,
      earlyPayment: undefined,
      expirationPayment: undefined,
      documents: [],
      id: 0,
    };
  };

  /**
   * @inheritdoc
   */
  async fetch() {
    const loan = await this.rpc.loanGetActive();

    if (!loan) {
      return () => {
        const model = this.getDefaultModel();
        this.setModel(model);
      };
    }

    let product: Product;

    switch (loan.product) {
      case LoanProduct.First180:
      case LoanProduct.First180Round:
        product = Product.FIRST;
        break;

      case LoanProduct.Repeat180Connoisseur:
      case LoanProduct.Repeat180Handyman:
      case LoanProduct.Repeat180Expert:
        product = Product.REPEAT;
        break;

      case LoanProduct.Smart:
        product = Product.SMART;
        break;

      case LoanProduct.Mini:
        product = Product.MINI;
        break;

      default: {
        throw new Error(`Unknown product alias ${loan.product}`);
      }
    }

    let status: Status;

    switch (loan.status) {
      case LoanStatus.ReadyForIssue:
        status = Status.SIGNING;
        break;

      case LoanStatus.Signed:
        status = Status.SIGNED;
        break;

      case LoanStatus.PayoutPending:
        status = Status.PAYOUT;
        break;

      case LoanStatus.Issued:
        status = Status.ACTIVE;
        break;

      case LoanStatus.Closed:
        status = Status.CLOSED;
        break;

      default: {
        throw new Error(`Unknown loan status ${loan.status}`);
      }
    }

    let type: Type;

    switch (product) {
      case Product.FIRST:
      case Product.REPEAT:
        type = Type.INSTALLMENT;
        break;

      case Product.SMART:
      case Product.MINI:
        type = Type.PDL;
        break;

      default:
        throw new Error(`Unknown product type ${product}`);
    }

    let interestRate: number;

    if (loan.basePercentRate == null && loan.dailyPercentRate == null) {
      throw new Error(
        `"loan.getActive().base_daily_percent_rate" and "loan.getActive().daily_percent_rate" cannot be undefined both`,
      );
    }

    if (loan.basePercentRate == null) {
      interestRate = loan.dailyPercentRate;
    } else if (loan.dailyPercentRate == null) {
      interestRate = loan.basePercentRate;
    } else {
      interestRate =
        loan.status === LoanStatus.Issued
          ? loan.dailyPercentRate
          : loan.basePercentRate;
    }

    const model: Model = {
      creationDate: loan.createdAt,
      interestRate,
      status,
      freePeriod: loan.freePeriod,
      product,
      productOptions: loan.productOptions,
      type,
      number: loan.loanNumber,
      amount: loan.amount,
      period: loan.period,
      id: loan.id,
      documents: loan.documents.map((document) => ({
        type: document.type as unknown as DocumentTypeOld,
        href: document.url,
        name: document.name,
      })),
      nextPayment: loan.paymentSummary.nextPayment,
      earlyPayment: loan.paymentSummary.earlyRepayment,
      expirationPayment: loan.paymentSummary.debt,
      payoutDate: loan.issuedAt,
    };

    // Костыль от бека. Проверка доступности оплаты для показа баннера о кредитных каникулах.
    // Бек обещает добавить опцию о кредитных каникулах в метод loan.getActive.
    try {
      await this.rpc.paymentGetInfo(
        PaymentProvider.Tinkoff,
        PaymentMethodType.TinkoffCard,
        ServiceType.Loan,
        loan.amount,
      );
    } catch (e) {
      if (RpcError.isType(e, RpcErrorCode.ActionBlocked)) {
        this.setIsActionBlocked(true);
      }
    }

    return () => {
      this.setModel(model);
    };
  }

  /**
   * Перенаправляет пользователя на страницу платежа и сохраняет выбранный тип
   * в хранилище.
   * @param type Тип погашения займа
   */
  public navigateToPayment(type: PaymentType) {
    this.payment.setPaymentType(type);

    this.routing.push(Page.REPAY);
  }

  /**
   * @inheritdoc
   */
  clear() {
    this.payment.setPaymentType(undefined);
    this.setModel(undefined);
  }
}
