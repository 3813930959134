import { FC } from 'react';
import { observer } from 'mobx-react';

import { Store } from '../../../stores';

import { Item } from '../Item';

/**
 * Отображает список чекбоксов.
 */
const List: FC = () => {
  const { model = [] } = Store.use();

  return (
    <>
      {model.map((value) => (
        <Item key={value.type} model={value} />
      ))}
    </>
  );
};

const component = observer(List);
export { component as List };
