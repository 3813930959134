import { ComponentPropsWithoutRef, FC } from 'react';

import { Root } from './Navigation.Root';
import { Next } from './Navigation.Next';

/**
 * Свойства компонента.
 */
export type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Номер шага, на котором используется блок. Есть три варианта: 1, 2, 3.
   */
  step: number;

  /**
   * Флаг указывает, что кнопки должны находиться в состоянии загрузки.
   */
  pending?: boolean;

  /**
   * Флаг указывает, что кнопки должны находиться в состоянии `disabled`.
   */
  disabled?: boolean;

  /**
   * Событие клика по кнопке "Далее"
   */
  onNext?: () => void;
};

/**
 * Компонент "Навигации". Отображает кнопку "Далее".
 */
export const Navigation: FC<Props> = ({
  step,
  pending = false,
  disabled = false,
  onNext = () => {},
  ...props
}) => {
  const isNext = step !== 3;

  return (
    <Root {...props} step={step}>
      {isNext && (
        <Next pending={pending} disabled={disabled} onClick={onNext}>
          Далее
        </Next>
      )}
    </Root>
  );
};
