import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

import { Next as Base } from './Next';

/**
 * Добавляем компоненту Next дополнительные стили.
 */
export const Next = styled(Base)`
  margin-left: auto;

  @media ${lessThanContainer('small')} {
    flex-grow: 1;

    margin-left: 0;
  }
`;
