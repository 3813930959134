import { ComponentPropsWithoutRef, FC } from 'react';

import { Container } from 'modules/common/ui';

import { useLayout } from '../../LayoutContext';

import { Text } from '../Text';

import { Root } from './AdditionalInfo.Root';
import { Content } from './AdditionalInfo.Content';
import { Description } from './AdditionalInfo.Description';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Root>, 'layout'>;

/**
 * Отображает дополнительный текст в подвале страницы.
 */
export const AdditionalInfo: FC<Props> = ({ ...props }) => {
  const { layout } = useLayout();

  return (
    <Root {...props}>
      <Container size="large" offset="small">
        <Content layout={layout}>
          <Description>
            <Text>
              Компания внесена в{' '}
              <a
                href="https://cbr.ru/microfinance/registry/"
                target="_blank"
                rel="noopener noreferrer"
              >
                госреестр Центрального банка России
              </a>{' '}
              и осуществляет свою деятельность в рамках Федеральных законов РФ.{' '}
              <a
                href="https://cbr.ru/"
                target="_blank"
                rel="noopener noreferrer"
              >
                ЦБ РФ
              </a>{' '}
              контролирует работу МФО и оказывает содействие физическим лицам, в
              онлайн-режиме доступна{' '}
              <a
                href="https://cbr.ru/reception"
                target="_blank"
                rel="noopener noreferrer"
              >
                интернет-приёмная Банка России
              </a>
              .
            </Text>
            <Text>
              Обращение можно адресовать также{' '}
              <a
                href="https://finombudsman.ru/"
                target="_blank"
                rel="noopener noreferrer"
              >
                финансовому уполномоченному (finombudsman.ru)
              </a>{' '}
              в соответствии со статьями 15-19 Федерального закона от 4 июня
              2018 года N 123-ФЗ «Об уполномоченном по правам потребителей
              финансовых услуг» (Собрание законодательства Российской Федерации,
              2018, N 24, ст. 3390).
            </Text>
            <Text>
              Адрес местонахождения и почтовый адрес организации: 119017, г.
              Москва, Старомонетный пер., дом 3. Номер телефона службы
              обеспечения деятельности финансового уполномоченного - 8 (800)
              200-00-10.
            </Text>
            <Text>
              Apollon Zaim является участником{' '}
              <a
                href="https://npmir.ru/"
                target="_blank"
                rel="noopener noreferrer"
              >
                СРО «МиР»
              </a>{' '}
              с 21.04.2016, организация защищает интересы МФО и заёмщиков и
              повышает финансовую грамотность, находится по адресу: 107078, г.
              Москва Орликов переулок, д.5, стр.1, этаж 2, пом.11.10.
            </Text>
          </Description>
        </Content>
      </Container>
    </Root>
  );
};
