import styled from '@emotion/styled';

import { Button, lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент компонента.
 */
export const Root = styled(Button)`
  padding-right: 0;
  padding-left: 0;

  width: 14rem;

  @media ${lessThanContainer('small')} {
    width: 100%;
  }
`;
