import { FC, ComponentPropsWithoutRef, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useFormikContext } from 'formik';

import { Store } from '../../../stores';

import { Root } from './Button.Root';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Хранилище состояния.
   */
  store: Store;
};

/**
 * Отображает кнопку получения кода подтверждения.
 */
const Button: FC<Props> = ({ store, ...props }) => {
  const form = useFormikContext();

  const handleClick = useCallback(() => {
    form.resetForm();
    store.requestCode();
  }, [store, form]);

  const disabled = store.phone == null || store.pending || store.countdown > 0;

  let text = 'Получить код';

  if (store.countdown > 0) {
    text += ` (${store.countdown} сек.)`;
  }

  return (
    <Root
      {...props}
      width="full"
      variant="filled"
      type="button"
      onClick={handleClick}
      disabled={disabled}
      pending={store.pending}
    >
      {text}
    </Root>
  );
};

const component = observer(Button);
export { component as Button };
