/**
 * Адрес страницы.
 */
export enum Page {
  /**
   * Главная страница сайта.
   */
  HOME = '/',

  /**
   * Страница "О нас".
   */
  ABOUT = '/about',

  /**
   * Странциа документации.
   */
  DOCUMENTS = '/documents',

  /**
   * Страница оплаты
   */
  PAY = '/pay',

  /**
   * Служебная страница, которая делает пользователя неавторизованным и сразу
   * же переадресовывает его на главную страницу сайта.
   */
  LOGOUT = '/logout',

  /**
   * Главная страница раздела авторизации.
   */
  LOGIN = '/login',

  /**
   * Страница ввода существующего пин-кода в разделе авторизации.
   */
  LOGIN_PIN = '/login/pin',

  /**
   * Страница установки пин-кода в разделе авторизации.
   */
  LOGIN_NEW_PIN = '/login/new-pin',

  /**
   * Страница восстановления пин-кода в разделе авторизации.
   */
  LOGIN_PIN_RECOVERY = '/login/pin-recovery',

  /**
   * Страница подтверждения смены пин-кода (либо установки нового пин-кода)
   * с помощью СМС в разделе авторизации.
   */
  LOGIN_PIN_CONFIRMATION = '/login/pin-confirmation',

  /**
   * Страница "Личный кабинет недоступен".
   */
  ACCOUNT_UNAVAILABLE = '/unavailable',

  /**
   * Главная страница личного кабинета. Фактически, является служебной странице,
   * на которой, в зависимости от состояния займа пользователя, происходит его
   * перенаправление на ту или иную страницу ЛК.
   */
  ACCOUNT = '/account',

  /**
   * Служебная страница, которая создаёт заявку на заём. Требует, чтобы в
   * параметрах были указаны сумма и срок займа (`?amount=900000&period=15`).
   */
  APPLICATION_NEW = '/application/new',

  /**
   * Страница первого шага заполнения заявки на займ.
   */
  APPLICATION_FIRST_STEP = '/application/1',

  /**
   * Страница второго шага заполнения заявки на займ.
   */
  APPLICATION_SECOND_STEP = '/application/2',

  /**
   * Страница третьего шага заполнения заявки на займ.
   */
  APPLICATION_THIRD_STEP = '/application/3',

  /**
   * Страница ожидания, пока по отправленной заявке будет принято решение.
   */
  UNDERWRITING = '/underwriting',

  /**
   * Страница отказа в займе.
   */
  REFUSAL = '/refusal',

  /**
   * Страница подписания договора на займ (сюда попадает пользователь, которому
   * после рассмотрения был одобрен займ).
   */
  CONTRACT = '/contract',

  /**
   * Страница получения денег.
   */
  PAYOUT = '/payout',

  /**
   * Страница идентификации пользователя при выдаче денег.
   */
  PAYOUT_IDENTIFICATION = '/payout/identification',

  /**
   * Страница ожидания, пока андерайтеры рассмотрят идентификационные данные
   * пользователя для выдачи денег.
   */
  PAYOUT_IDENTIFICATION_PENDING = '/payout/identification/pending',

  /**
   * Страница ошибки добавления новой карты.
   */
  PAYOUT_NEW_CARD_FAILURE = '/payout/new-card/failure',

  /**
   * Страница успеха добавления новой карты.
   */
  PAYOUT_NEW_CARD_SUCCESS = '/payout/new-card/success',

  /**
   * Страница ожидания, пока андерайтеры заканчивают идентификацию
   * пользователя, прежде чем отправить ему деньги.
   */
  PAYOUT_PENDING = '/payout/pending',

  /**
   * Страница ожидания, пока деньги придут клиенту.
   */
  PAYOUT_SUCCESS = '/payout/success',

  /**
   * Страница активного займа.
   */
  LOAN = '/loan',

  /**
   * Страница погашения займа.
   */
  REPAY = '/repay/repay',

  /**
   * Страница отказа от услуги.
   */
  REFUSAL_OF_SERVICE = '/refusal-of-service',

  /**
   * Страница заявки по отказу от услуги передана в работу.
   */
  REFUSAL_OF_SERVICE_WORK = '/refusal-of-service/work',

  /**
   * Страница юридическая поддержка.
   */
  LEGAL_SUPPORT = '/legal-support',

  /**
   * Страница ошибки покупки услуги на странице юридическая поддержка.
   */
  LEGAL_SUPPORT_FAILURE = '/legal-support/failure',

  /**
   * Страница заполнения заявки на покупки услуги.
   */
  LEGAL_SUPPORT_TAKE = '/legal-support/take',

  /**
   * Страница оплаты юридической услуги.
   */
  LEGAL_SUPPORT_PAY = '/legal-support/pay',

  /**
   * Cтраница подписания договора на реструктуризацию.
   */
  RESTRUCTURE = '/restructure',
}
