import { ComponentPropsWithoutRef, FC } from 'react';

import { Button } from 'modules/common/ui';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Button>, 'color' | 'variant'>;

/**
 * Отображает кнопку "Далее".
 */
export const Next: FC<Props> = ({
  children,
  pending = false,
  onClick,
  ...props
}) => (
  <Button
    {...props}
    color="primary"
    variant="filled"
    pending={pending}
    onClick={onClick}
  >
    {children}
  </Button>
);
