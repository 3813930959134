import styled from '@emotion/styled';

import { lessThanContainer, LinkButton as Link } from 'modules/common/ui';

/**
 * Ссылка элемента вложенного меню шапки.
 */
export const LinkButton = styled(Link)`
  display: block;

  padding: 0.5rem 1.25rem;

  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: ${({ theme }) => theme.colors.light.text.primary};

  text-decoration: none;
  text-align: left;

  white-space: nowrap;

  border-radius: 0;

  :hover {
    background-color: ${({ theme }) => theme.colors.light.background.secondary};
  }

  @media ${lessThanContainer('medium')} {
    padding: 0.75rem 1.25rem;

    font-weight: normal;
    font-size: 0.812rem;
    line-height: 1.25rem;
  }
`;
