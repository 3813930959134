import { FC, ComponentPropsWithoutRef, useCallback } from 'react';
import { observer } from 'mobx-react';

import { useFormGroup } from 'modules/common/ui';

import { Store } from '../../../stores';

import { Navigation as Base } from '../../Navigation';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Base>;

/**
 * Отображает навигацию по шагам заявки.
 */
const Navigation: FC<Props> = ({ step, ...props }) => {
  const forms = useFormGroup();
  const store = Store.use();

  const handleNext = useCallback(() => {
    forms.submit();
  }, [forms]);

  return (
    <Base {...props} step={step} pending={store.pending} onNext={handleNext} />
  );
};

const component = observer(Navigation);
export { component as Navigation };
