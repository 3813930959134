import styled from '@emotion/styled';

import { transition } from 'modules/common/theme';
import { lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент калькулятора.
 */
export const Button = styled.button`
  height: 70px;
  width: 280px;
  background: white;
  color: black;
  border-radius: 35px;
  border: none;
  outline: none;
  cursor: pointer;
  overflow: hidden;

  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: -0.01em;
  position: relative;
  transition: ${transition('all')};

  &::before {
    content: ' ';
    display: block;
    position: absolute;

    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 35px;
    background: #0f123b;
    transition: ${transition('transform')};
    transform: translateY(100%);
  }

  @keyframes slide-animation {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    50% {
      transform: translateY(-25px);
      opacity: 0;
    }
    51% {
      transform: translateY(25px);
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &:hover {
    color: white;
    &::before {
      transform: translateY(0);
    }
    background: transparent;
    & span span {
      animation: slide-animation 0.25s ease-in-out both;
    }
  }

  & span {
    position: relative;
    display: inline-block;
    overflow: hidden;
    height: 25px;
    & span {
      transition: ${transition('transform')};
    }
  }

  @media ${lessThanContainer('small')} {
    width: 100%;
    margin-top: 15px;
  }

  @media ${lessThanContainer('xSmall')} {
    margin-top: 5px;
  }

  @media ${lessThanContainer('medium')} and (orientation: landscape) {
    height: 50px;
  }
`;
