import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Номер шага, на котором используется блок. Есть три варианта: 1, 2, 3.
   * На шаге 3 должен исчезать border-top.
   */
  step: number;
};

/**
 * Корневой элемент Navigation.
 */
export const Root = styled.div<Props>`
  display: flex;
  justify-content: space-between;

  margin-top: 2rem;
  padding-top: 2rem;

  border-top-style: solid;
  border-top-width: 1px;

  ${(p) =>
    p.step === 3
      ? css`
          border-color: ${p.theme.colors.light.background.primary};
        `
      : css`
          border-color: ${p.theme.colors.light.separator};
        `}

  @media ${lessThanContainer('small')} {
    flex-direction: column;

    margin-top: 0;
    padding-top: 2.5rem;

    border-top: none;
  }
`;
