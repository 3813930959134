import { ComponentPropsWithoutRef, FC, useCallback } from 'react';

import { Page } from 'modules/common/routing';

import { useLayout } from '../../LayoutContext';

import { useMenu } from '../MenuContext';

import { Root } from './Menu.Root';
import { Item } from './Menu.Item';
import { LinkButton } from './Menu.LinkButton';
import { Text } from './Menu.Text';
import { SubMenu } from './SubMenu';
import { Divider } from './Menu.Divider';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Меню шапки. Убрать комментарии при появлении вложенного меню.
 */
export const Menu: FC<Props> = ({ ...props }) => {
  const { setOpen } = useMenu();
  const { layout } = useLayout();

  const handleClick = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Root {...props}>
      <Item>
        <LinkButton href={Page.ABOUT} onClick={handleClick} layout={layout}>
          О нас
        </LinkButton>
      </Item>
      <Divider />
      <Item>
        <LinkButton href={Page.DOCUMENTS} onClick={handleClick} layout={layout}>
          Документы
        </LinkButton>
      </Item>
      <Divider />
      <Item>
        <LinkButton href={Page.PAY} onClick={handleClick} layout={layout}>
          Оплатить
        </LinkButton>
      </Item>
      <Divider />
      <Item>
        <Text layout={layout}>Еще</Text>
        <SubMenu />
      </Item>
    </Root>
  );
};
