import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Единица элемента вложенного меню шапки.
 */
export const Item = styled.li`
  @media ${lessThanContainer('medium')} {
  }
`;
