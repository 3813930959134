/**
 * Продукт, которым является заём. Все выданные займы классифицируются по
 * продуктам.
 */
export enum LoanProduct {
  /**
   * Заём для первичного клиента. Он же "Кредит Доверия 1" или "КД1".
   * Данный тип займов является устаревшим и может встречаться только у клиентов
   * ранее оформивших заём. Его функционал заменён типом {First180Round}
   */
  First180 = 'first_180',

  /**
   * Заём для первичного клиента. Он же "Кредит Доверия 1" или "КД1" с
   * округлением сумм.
   */
  First180Round = 'first_180_round',

  /**
   * Заём для повторного клиента. Он же "Кредит Доверия 2" или "КД2" с подтипом
   * "умелец" (до 6 займов).
   */
  Repeat180Handyman = 'repeat_180_handyman',

  /**
   * Заём для повторного клиента. Он же "Кредит Доверия 2" или "КД2" с подтипом
   * "эксперт" (от 6 до 12 займов).
   */
  Repeat180Expert = 'repeat_180_expert',

  /**
   * Заём для повторного клиента. Он же "Кредит Доверия 2" или "КД2" с подтипом
   * "ценитель" (от 12 займов).
   */
  Repeat180Connoisseur = 'repeat_180_connoisseur',

  /**
   * Продукт с потенциально нежелательными для клиента условиями. Обычно
   * предлагается клиенту, чтобы подтолкнуть его к выбору другого продукта.
   */
  Mini = 'mini',

  /**
   * Продукт "Смарт".
   */
  Smart = 'smart',
}
