import { ComponentPropsWithoutRef, FC } from 'react';
import { useRouter } from 'next/router';

import { Page } from 'modules/common/routing';
import { useLayout } from '../LayoutContext';

import { Root } from './Footer.Root';
import { Main } from './Main';
import { AdditionalInfo } from './AdditionalInfo';
import { List } from './List';
import { Small } from './Small';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Root>, 'layout'>;

/**
 * Отображает подвал страницы.
 */
export const Footer: FC<Props> = ({ ...props }) => {
  const { layout } = useLayout();
  const { pathname } = useRouter();

  const isAccountPage =
    pathname !== Page.HOME &&
    pathname !== Page.ABOUT &&
    pathname !== Page.DOCUMENTS &&
    pathname !== Page.LEGAL_SUPPORT;

  return (
    <Root {...props} layout={layout}>
      {layout === 'screen' ? (
        <>
          <AdditionalInfo />
          <List />
        </>
      ) : null}
      {isAccountPage ? <Small /> : <Main />}
    </Root>
  );
};
