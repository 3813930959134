import styled from '@emotion/styled';

/**
 * Компонент для отображение заголовка.
 */
export const Title = styled.h5`
  margin: 1.875rem 0 0;

  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;

  :first-of-type {
    margin-top: 0;
  }
`;
