import { ComponentPropsWithoutRef, FC, useCallback } from 'react';
import { InferType } from 'yup';

import { Form as Base, FormItem } from 'modules/application/common';
import { Store } from 'modules/application/statement';
import { UserCreditObligations } from 'services/RpcService/types/UserCreditObligations';

import { schema } from '../../schemas';
import { Field as CreditObligationsField } from '../../types';

import { CreditObligations } from '../CreditObligations';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Base>,
  'onSubmit' | 'schema' | 'initialValues'
>;

/**
 * Отображает форму "Информация о кредитных обязательствах".
 */
export const Form: FC<Props> = ({ ...props }) => {
  const store = Store.use();

  const handleSubmit = useCallback(
    (form: InferType<typeof schema>) => {
      store.updateValues({
        creditObligations: form.creditObligations as UserCreditObligations,
      });
    },
    [store],
  );

  const initialValues = {
    [CreditObligationsField.CREDIT_OBLIGATIONS]: store.model?.creditObligations,
  };

  return (
    <Base
      {...props}
      onSubmit={handleSubmit}
      schema={schema}
      initialValues={initialValues}
    >
      <FormItem>
        <CreditObligations />
      </FormItem>
    </Base>
  );
};
