/**
 * Возвращает record-тип, удаляя все поля, которые имеют значение undefined
 * либо null.
 * @param form Данные формы.
 */
export function mapOmitNullish<
  TFormType extends Record<string, string | boolean | null | undefined>,
>(form: TFormType) {
  return Object.keys(form).reduce((acc, key: keyof TFormType) => {
    const value = form[key];

    if (value !== undefined && value !== null) {
      acc[key] = value;
    }

    return acc;
  }, {} as TFormType);
}
