import { FC } from 'react';

import { Button } from './Button';

export const Submit: FC<{ onSubmit?: () => void }> = ({ onSubmit }) => {
  return (
    <Button onClick={() => onSubmit && onSubmit()}>
      <span>
        <span>Оформить</span>
      </span>
    </Button>
  );
};
