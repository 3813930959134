import { ApplicationAgreement } from '../types/ApplicationAgreement';

/**
 * Преобразует ответ сервера в данные о соглашениях.
 * @param data Ответ сервера.
 */
export function transformApplicationAgreements(
  data: any,
): ApplicationAgreement[] {
  return (data as any[]).map((item) => ({
    type: item.name,
    info: item.info,
    required: item.is_required,
    isCheckedByDefault: item.is_default_checked,
    documentUrl: item.document_url,
  }));
}
