import { FC, ComponentPropsWithoutRef } from 'react';

import { Field, getEnumValues, Select } from 'modules/common/ui';
import { UserCreditObligations } from 'services/RpcService/types/UserCreditObligations';

import { Field as CreditObligationsField } from '../../types';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Select>,
  'values' | 'format' | 'readOnly'
>;

const recordProps = getEnumValues(UserCreditObligations, {
  [UserCreditObligations.Zero]: 'нет платежей по кредитам',
  [UserCreditObligations.LessThan10]: 'до 10 000 ₽',
  [UserCreditObligations.From10To20]: '10 001 - 20 000 ₽',
  [UserCreditObligations.From20To35]: '20 001 ₽ - 35 000 ₽',
  [UserCreditObligations.From35To50]: '35 001 - 50 000 ₽',
  [UserCreditObligations.MoreThan50]: 'более 50 000 ₽',
});

/**
 * Отображает поле "Кредитные обязательства".
 */
export const CreditObligations: FC<Props> = (props) => (
  <Field
    htmlFor="size"
    label="Кредитные обязательства"
    name={CreditObligationsField.CREDIT_OBLIGATIONS}
  >
    <Select
      {...props}
      {...recordProps}
      id="size"
      name={CreditObligationsField.CREDIT_OBLIGATIONS}
      readOnly
    />
  </Field>
);
